<template>
    <vue-progress-bar></vue-progress-bar>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'App',
    watch: {
        '$i18n.locale'(to) {
            if(to == 'ar') document.documentElement.setAttribute('lang', 'ar');
            else document.documentElement.setAttribute('lang', 'en');
        }
    }
}
</script>

<style>
* {
    font-size: 0.96rem;
}

@font-face {
    font-family: 'lato-bold';
    src: url('./fonts/lato/Lato-Bold.ttf');
}

@font-face {
    font-family: 'lato-regular';
    src: url('./fonts/lato/Lato-Regular.ttf');
}

@font-face {
    font-family: 'tajawal-regular';
    src: url('./fonts/tajawal/Tajawal-Regular.ttf');
}

@font-face {
    font-family: 'tajawal-bold';
    src: url('./fonts/tajawal/Tajawal-Bold.ttf');
}

html[lang="en"] {
    font-family: lato-regular;
}

html[lang="ar"] {
    font-family: tajawal-regular;
}

html > * {
    font-family: inherit;
}

h1, p {
    margin: 0;
}

button.btn-main {
    background-color: #009783;
}

button.btn-delete {
    background-color: #d11717;
}

button.btn-main, button.btn-delete {
    color: #ffffff;
    margin: 10px 0;
    padding: 8px 15px;
    transition: all 0.2s ease;
}

button.btn-main:hover, button.btn-delete:hover, .btn-add:hover {
    filter: contrast(125%);
    color: #ffffff;
}

.btn {
    margin: 3px 3px !important;
}

.btn-add {
    background-color: #133f39;
    border-radius: 5px;
    transition: all 0.2s ease;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.invalid-feedback {
    display: block !important;
}

input.invalid, textarea.invalid, select.invalid {
    border-color: #dc3545 !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

input.invalid, textarea.invalid {
    padding-right: calc(1.5em + .75rem);
    background-position: right calc(.375em + .1875rem) center;
}

*[dir="rtl"] input.invalid, textarea.invalid {
    background-position: left calc(.375em + .1875rem) center;
    padding-left: calc(1.5em + .75rem);
}
</style>
import axiosApiInstance from '../api';
import { error } from '../utils/apiHelpers';

export default {
    state: {},
    mutations: {},
    actions: {
        fetchReports(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/report', { params: payload })
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchReportsCount(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/report/count', { params: payload })
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchReport(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/report/' + payload.id)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchReportFiles(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/file/report/' + payload.id)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchReportFile(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/file/report/' + payload.id + '/' + payload.key)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        updateReport(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.patch('/report/' + payload.id, payload.patch)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        addReport(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.post('/report', payload)
                .then(res => {
                    if(res.status == 201) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        deleteReport(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.delete('/report/' + payload.id)
                .then(res => {
                    if(res.status == 204) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        uploadReportFiles(_, payload) {
            return new Promise((resolve, reject) => {

                let data = new FormData();
                for(let file of payload.files) {
                    data.append('files', file);
                }

                axiosApiInstance.post(`/file/report/${payload.id}`, data, {headers: { "Content-Type": "multipart/form-data" }})
                .then(res => {
                    if(res.status == 200) {
                        resolve();
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchTransactions(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/transaction', { params: payload })
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchTransactionsCount(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/transaction/count', { params: payload })
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchTransactionsAdmin(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/transaction/all', { params: payload })
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchTransactionsCountAdmin(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/transaction/all/count', { params: payload })
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchTransaction(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/transaction/' + payload.id)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchTransactionFiles(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/file/' + payload.id)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchTransactionFile(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/file/' + payload.id + '/' + payload.key)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        updateTransaction(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.patch('/transaction/' + payload.id, payload.patch)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        sendPaymentLink(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.post('/transaction/notify/' + payload.id)
                .then(res => {
                    if(res.status == 200) {
                        resolve();
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        addTransaction(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.post('/transaction', payload)
                .then(res => {
                    if(res.status == 201) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        deleteTransaction(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.delete('/transaction/' + payload.id)
                .then(res => {
                    if(res.status == 204) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchAccounts(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/account/all', { params: { limit: payload.limit, offset: payload.offset }})
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchAccountNames(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/account/all/names', { params: { limit: payload.limit, offset: payload.offset }})
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        addAccount(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.post('/account', payload)
                .then(res => {
                    if(res.status == 201) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchAccountById(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get(`/account/${payload.id}`)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchAccount() {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get(`/account`)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        updateAccount(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.patch(`/account/${payload.id}`, payload)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        deleteAccount(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.delete('/account/' + payload.id)
                .then(res => {
                    if(res.status == 204) {
                        resolve();
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        deleteFile(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.post(`/file/delete`, payload)
                .then(res => {
                    if(res.status == 204) {
                        resolve();
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        uploadFiles(_, payload) {
            return new Promise((resolve, reject) => {

                let data = new FormData();
                for(let file of payload.files) {
                    data.append('files', file);
                }

                axiosApiInstance.post(`/file/${payload.id}`, data, {headers: { "Content-Type": "multipart/form-data" }})
                .then(res => {
                    if(res.status == 200) {
                        resolve();
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        createInvoice(_, payload) {
            return new Promise((resolve, reject) => {

                axiosApiInstance.post(`/invoice/documents/${payload.id}`, payload)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data.res);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        fetchAnalytics(_, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.get('/analytics', { params: { before: payload.before, after: payload.after }})
                .then(res => {
                    if(res.status == 200) {
                        resolve(res.data);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        }
    },
}

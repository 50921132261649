<template>
    <nav class="navbar navbar-expand-lg navbar-light" :class="[ $i18n.locale == 'en' ? 'en' : 'ar' ]" :style="[ !($route.path != `/${$i18n.locale}` || $route.path != `/${$i18n.locale}/`) ? 'border-bottom: 2.5px solid #f6f6f6;' : 'border: none']">
        <div class="container">
            <router-link class="navbar-brand" :to="`/${$i18n.locale}`">
                <img src="../../../assets/DorchesterLogo_AK.png" alt="Dorchester" height="40">
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" :class="{ booking : $route.path.includes('book') }" id="navbarNavAltMarkup">
                <div class="navbar-nav ms-auto">
                    <router-link :to="`/${$i18n.locale}`" class="nav-link" :class="{ active : $route.path == `/${$i18n.locale}` || $route.path == `/${$i18n.locale}/` }">{{ $t('index.nav.home') }}</router-link>
                    <a href="https://dorchesterpropertiesregistrationtrusteellc.tawk.help/" class="nav-link" target="_blank">{{ $t('index.nav.services') }}</a>
                    <router-link :to="`/${$i18n.locale}/book`" class="nav-link" :class="{ active : $route.path == `/${$i18n.locale}/book` || $route.path == `/${$i18n.locale}/book/` }">{{ $t('index.nav.book') }}</router-link>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="../../../assets/icons/united-kingdom.png" alt="English" height="20" v-if="$i18n.locale == 'en'">
                            <img src="../../../assets/icons/united-arab-emirates.png" alt="Arabic" height="20" v-else>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                            <li class="en">
                                <a class="dropdown-item" href="#" @click.prevent="setLocale('en')">
                                    <img src="../../../assets/icons/united-kingdom.png" alt="English" height="20">English
                                </a>
                            </li>
                            <li class="ar">
                                <a class="dropdown-item" href="#" @click.prevent="setLocale('ar')">
                                    <img src="../../../assets/icons/united-arab-emirates.png" alt="Arabic" height="20">العربية
                                </a>
                            </li>
                        </ul>
                    </li>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Navbar',
    methods: {
        setLocale(locale) {
            this.$i18n.locale = locale;
            this.$router.push({
                params: { lang: locale }
            });
        }
    }
}
</script>

<style scoped>
.navbar {
    background-color: transparent !important;
    padding: 15px;
}

.navbar-nav > a {
    margin: 0 10px !important;
}

li.dropdown {
    margin: 0 0 0 10px !important;
}

li img {
    margin-right: 10px;
}

.ar {
    font-family: tajawal-bold;
}

.en {
    font-family: lato-bold;
}

.dropdown-menu {
    background-color: rgba(82, 77, 73, 0.541);
    backdrop-filter: blur(3.5px);
    border-radius: 3px;
}

li a {
    color: #ffffff;
}

@media only screen and (max-width: 991px) {
    .navbar-collapse {
        position: absolute;
        top: 72.88px;
        z-index: 20;
    }

    .navbar-collapse.booking {
        background: #eeebebc5;
        width: 100%;
        left: 0;
        padding: 0 20px;
    }
}
</style>
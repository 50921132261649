<template>
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <h2>{{ $t('404') }}</h2>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'Not Found'
}
</script>

<style scoped>
.row {
    margin-top: 50px;
}
</style>
<template>
	<Overlay :on="dialogue.show" @overlayOff="dialogue.show = false;"></Overlay>
	<Dialogue v-if="dialogue.show" :title="$t('dashboard.user.deleteMessage')" :loading="loading" :deleteDialogue="true"
		:description="$t('dashboard.user.deleteDescription')" :ok="$t('dashboard.user.yes')"
		:cancel="$t('dashboard.user.cancel')" @yes="deleteUser" @cancel="dialogue.show = false;"></Dialogue>
	<div class="container" :dir="[$i18n.locale == 'ar' ? 'rtl' : '']">
		<div class="row justify-content-center" v-if="resetPassword != 'true'">
			<div class="col-12 col-lg-10">
				<div class="header">
					<h1>{{ $t("dashboard.user.title") }}</h1>
					<div class="buttons">
						<button class="btn btn-delete" @click.prevent="dialogue.show = true"
							:disabled="loading || role != 'admin'">{{ $t("dashboard.user.delete") }}</button>
						<button class="btn btn-main" @click.prevent="validateForm" :disabled="loading">{{
							$t("dashboard.user.save") }}</button>
						<button class="btn btn-main" @click.prevent="resetPassword = 'true';" :disabled="loading">{{
							$t("dashboard.user.changePassword") }}</button>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3">
				<div class="card">
					<h4>{{ $t("dashboard.addUser.userInformation") }}</h4>
					<div class="row mb-3">
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="role" class="col-form-label col-sm-3">{{ $t("dashboard.addUser.role") }}</label>
								<div class="col-sm-9">
									<select class="form-select" v-model="inputs.role"
										v-bind:class="{ invalid: errors.role }" id="role" :disabled="role != 'admin'">
										<option disabled selected value="null">{{ $t('dashboard.addUser.chooseRole') }}
										</option>
										<option :value="$t('dashboard.addUser.roles.admin.id')">{{
											$t('dashboard.addUser.roles.admin.value') }}</option>
										<option :value="$t('dashboard.addUser.roles.user.id')">{{
											$t('dashboard.addUser.roles.user.value') }}</option>
										<option :value="$t('dashboard.addUser.roles.reportUser.id')">{{
											$t('dashboard.addUser.roles.reportUser.value') }}</option>
										<option :value="$t('dashboard.addUser.roles.superUser.id')">{{
											$t('dashboard.addUser.roles.superUser.value') }}</option>
									</select>
									<div class="invalid-feedback" v-if="errors.role">
										{{ errors.role }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="name" class="col-form-label col-sm-3">{{ $t("dashboard.addUser.name") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid: errors.name }" type="text" class="form-control"
										id="name" v-model="inputs.name" autocomplete="off" maxlength="50" />
									<div class="invalid-feedback" v-if="errors.name">
										{{ errors.name }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="loginMethod" class="col-form-label col-sm-3">{{
									$t("dashboard.addUser.loginMethod") }}</label>
								<div class="col-sm-9">
									<select class="form-select" v-model="inputs.loginMethod"
										v-bind:class="{ invalid: errors.loginMethod }" id="loginMethod"
										:disabled="role != 'admin'">
										<option disabled selected value="null">{{ $t('dashboard.addUser.chooseLoginMethod')
										}}</option>
										<option disabled :value="$t('dashboard.addUser.loginMethods.PASS.id')">{{
											$t('dashboard.addUser.loginMethods.PASS.value') }}</option>
										<option :value="$t('dashboard.addUser.loginMethods.MFA-MOBILE.id')">{{
											$t('dashboard.addUser.loginMethods.MFA-MOBILE.value') }}</option>
										<option :value="$t('dashboard.addUser.loginMethods.MFA-EMAIL.id')">{{
											$t('dashboard.addUser.loginMethods.MFA-EMAIL.value') }}</option>
									</select>
									<div class="invalid-feedback" v-if="errors.loginMethod">
										{{ errors.loginMethod }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="accountStatus" class="col-form-label col-sm-3">{{
									$t("dashboard.addUser.accountStatus") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid: errors.accountStatus }" type="checkbox" class=""
										id="accountStatus" v-model="inputs.accountStatus" :disabled="role != 'admin'" />
									<div class="invalid-feedback" v-if="errors.accountStatus">
										{{ errors.accountStatus }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="email" class="col-form-label col-sm-3">{{ $t("dashboard.addUser.email")
								}}</label>
								<div class="col-sm-9">
									<input type="email" class="form-control" id="email" v-model="inputs.email"
										autocomplete="off" maxlength="100" />
									<div class="invalid-feedback" v-if="errors.email">
										{{ errors.email }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="mobileNumber" class="col-form-label col-sm-3">{{
									$t("dashboard.addUser.mobileNumber") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid: errors.mobileNumber }" type="text" class="form-control"
										id="mobileNumber" v-model="inputs.mobileNumber" autocomplete="off" maxlength="10" />
									<div class="invalid-feedback" v-if="errors.mobileNumber">
										{{ errors.mobileNumber }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="requiresLogin" class="col-form-label col-sm-3">{{
									$t("dashboard.user.requiresLogin") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid: errors.requiresLogin }" type="checkbox" class=""
										id="requiresLogin" v-model="inputs.requiresLogin" disabled />
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="role" class="col-form-label col-sm-3">{{ $t("dashboard.users.createdAt")
								}}</label>
								<div class="col-sm-9">
									<input class="form-control" :value="dateFormat(inputs.createdAt)" disabled />
								</div>
							</div>
							<div class="row mb-3">
								<label for="role" class="col-form-label col-sm-3">{{ $t("dashboard.users.loginCount")
								}}</label>
								<div class="col-sm-9">
									<input class="form-control" :value="inputs.loginCount" disabled />
								</div>
							</div>
							<div class="row mb-3">
								<label for="role" class="col-form-label col-sm-3">{{ $t("dashboard.users.failedLoginCount")
								}}</label>
								<div class="col-sm-9">
									<input class="form-control" :value="inputs.failedLoginCount" disabled />
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="role" class="col-form-label col-sm-3">{{ $t("dashboard.users.updatedAt")
								}}</label>
								<div class="col-sm-9">
									<input class="form-control" :value="returnTimePassed(inputs.updatedAt, $i18n.locale)"
										disabled />
								</div>
							</div>
							<div class="row mb-3">
								<label for="role" class="col-form-label col-sm-3">{{ $t("dashboard.users.lastLogin")
								}}</label>
								<div class="col-sm-9">
									<input class="form-control" :value="returnTimePassed(inputs.lastLogin, $i18n.locale)"
										disabled />
								</div>
							</div>
							<div class="row mb-3">
								<label for="role" class="col-form-label col-sm-3">{{ $t("dashboard.users.lastFailedLogin")
								}}</label>
								<div class="col-sm-9">
									<input class="form-control"
										:value="returnTimePassed(inputs.lastFailedLogin, $i18n.locale)" disabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3" v-if="Object.keys(history) != 0">
				<div class="card">
					<h4>{{ $t("dashboard.user.userHistory") }}</h4>
					<div class="row mb-3">
						<div class="col-lg-12">
							<table class="table">
								<thead>
									<tr>
										<th scope="col" class="text-nowrap" :class="$i18n.locale" v-bind:key="index"
											v-for="(column, index) in columns">{{ column }}</th>
									</tr>
								</thead>
								<tbody v-if="$i18n.locale == 'en'">
									<tr v-bind:key="index" v-for="(h, index) in history">
										<td class="text-wrap">{{ h.accessDescription }}</td>
										<td class="text-wrap"><button class="btn" :disabled="inputs.id == h.accountId"><router-link :to="`/${$i18n.locale}/!/user/${h.accountId}`">{{
												$t('dashboard.user.viewAccount') }}</router-link></button></td>
										<td>{{ h.userAgent }}</td>
										<td>{{ h.ip }}</td>
										<td>{{ dateFormat(h.createdAt) }}</td>
										<td v-if="h.type == 'ACCOUNT'"><button class="btn" :disabled="inputs.id == h.accountId"><router-link :to="`/${$i18n.locale}/!/user/${h.typeId}`">{{
											$t('dashboard.user.viewAccount') }}</router-link></button></td>
										<td v-if="h.type == 'REPORT'"><router-link :to="`/${$i18n.locale}/!/report/${h.typeId}`">{{
											$t('dashboard.user.viewReport') }}</router-link></td>
										<td v-if="h.type == 'TRANSACTION'"><router-link :to="`/${$i18n.locale}/!/${h.typeId}`">{{
											$t('dashboard.user.viewTransaction') }}</router-link></td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr v-bind:key="index" v-for="(h, index) in history">
										<td v-if="h.type == 'ACCOUNT'"><button class="btn" :disabled="inputs.id == h.accountId"><router-link :to="`/${$i18n.locale}/!/user/${h.typeId}`">{{
											$t('dashboard.user.viewAccount') }}</router-link></button></td>
										<td v-if="h.type == 'REPORT'"><router-link :to="`/${$i18n.locale}/!/report/${h.typeId}`">{{
											$t('dashboard.user.viewReport') }}</router-link></td>
										<td v-if="h.type == 'TRANSACTION'"><router-link :to="`/${$i18n.locale}/!/${h.typeId}`">{{
											$t('dashboard.user.viewTransaction') }}</router-link></td>
										<td>{{ dateFormat(h.createdAt) }}</td>
										<td>{{ h.ip }}</td>
										<td>{{ h.userAgent }}</td>
										<td class="text-wrap"><button class="btn" :disabled="inputs.id == h.accountId"><router-link :to="`/${$i18n.locale}/!/user/${h.accountId}`">{{
												$t('dashboard.user.viewAccount') }}</router-link></button></td>
										<td class="text-wrap">{{ h.accessDescription }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-center" v-if="resetPassword == 'true'">
			<div class="col-12 col-lg-10 mb-3 mt-3">
				<div class="card">
					<h4 class="mb-3 text-center">{{ $t("dashboard.user.passwordReset") }}</h4>
					<div class="row mb-3">
						<div class="col-lg-6 offset-3">
							<div class="row mb-3">
								<label for="password" class="col-form-label col-sm-3">{{ $t("dashboard.addUser.password")
								}}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid: errors.password }" type="password" class="form-control"
										id="password" v-model="inputs.password" autocomplete="new-password"
										maxlength="50" />
									<div class="invalid-feedback" v-if="errors.password">
										{{ errors.password }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="confirmPassword" class="col-form-label col-sm-3">{{
									$t("dashboard.user.confirmPassword")
								}}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid: errors.password }" type="password" class="form-control"
										id="confirmPassword" v-model="inputs.confirmPassword" autocomplete="new-password"
										maxlength="50" />
								</div>
							</div>
							<div class="row mb-3 justify-content-center">
								<p>{{
									$t("dashboard.user.passwordRequirments") }}</p>
							</div>
							<div class="row mb-3 justify-content-center">
								<button class="btn btn-main" style="width: 140px;" @click.prevent="validateForm"
									:disabled="loading">{{
										$t("dashboard.user.changePassword") }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import validator from 'validator';
import { mapActions, mapGetters } from "vuex";
import Dialogue from '../../utils/Dialogue';
import Overlay from '../../utils/Overlay';
import { dateFormat, returnTimePassed } from '../../../utils/formatters'
import cookie from 'vue-cookies';
export default {
	name: "User",
	data() {
		return {
			columns: [
				this.$t('dashboard.user.accessDescription'),
				this.$t('dashboard.user.actionBy'),
				this.$t('dashboard.user.userAgent'),
				this.$t('dashboard.user.ip'),
				this.$t('dashboard.user.date'),
				this.$t('dashboard.user.view'),
			],
			loading: false,
			formValidated: false,
			user: {},
			history: {},
			resetPassword: false,
			inputs: {
				id: null,
				role: null,
				email: null,
				name: null,
				password: null,
				mobileNumber: null,
				loginMethod: null,
				accountStatus: null,
				confirmPassword: null,
			},
			errors: {
				role: null,
				email: null,
				name: null,
				password: null,
				mobileNumber: null,
				loginMethod: null,
				accountStatus: null,
			},
			dialogue: {
				show: false,
			}
		}
	},
	async created() {
		if (this.$i18n.locale == 'ar') {
			this.columns.reverse();
		}
		await this.initial();
	},
	computed: {
		...mapGetters(['role', 'id'])
	},
	methods: {
		...mapActions([
			'fetchAccount',
			'fetchAccountById',
			'updateAccount',
			'deleteAccount'
		]),
		dateFormat, returnTimePassed,
		async initial() {
			let id = this.$route.params.id;
			this.resetPassword = this.$route.query.reset;
			if (this.id != id) {
				let payload = { id };
				this.inputs.id = id;

				this.loading = true;
				await this.fetchAccountById(payload)
					.then(data => {
						this.user = { ...data.account };
						this.inputs = { ...data.account };
						this.history = { ...data.history };
					})
					.catch(err => {
						this.$toast.clear();
						this.$toast.show(err.msg);
					})
					.finally(() => {
						this.loading = false;
					});
			} else {
				this.loading = true;
				await this.fetchAccount()
					.then(data => {
						this.user = { ...data.account };
						this.inputs = { ...data.account };
						if (data.history != null) {
							this.history = { ...data.history };
						}
					})
					.catch(err => {
						this.$toast.clear();
						this.$toast.show(err.msg);
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		async deleteUser() {
			if (this.loading) return;
			this.dialogue.show = false;
			let payload = {};
			payload.id = this.user.id;
			this.loading = true;
			await this.deleteAccount(payload)
				.then(() => {
					this.$toast.show('User successfully deleted');
					this.$router.push(`/${this.$i18n.locale}/!/users`);
				})
				.catch(err => {
					this.$toast.clear();
					this.$toast.error(err.msg || err.status);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		async validateForm() {
			if (this.loading) return;
			this.formValidated = true;

			const nameValid = this.nameValid();
			const emailValid = this.emailValid();
			const mobileNumberValid = this.mobileNumberValid();
			const roleValid = this.roleValid();
			const loginMethodValid = this.loginMethodValid();
			const passwordValid = this.passwordValid();

			let passwordMatch = false;
			if (this.resetPassword == 'true') {
				passwordMatch = (this.inputs.confirmPassword === this.inputs.password)
				if (passwordMatch == false) {
					this.errors.password = this.$i18n.locale == 'en' ? `Passwords don't match` : 'كلمات المرور غير متطابقة';
				}
			} else passwordMatch = true

			if (nameValid && emailValid && roleValid && passwordValid && mobileNumberValid && loginMethodValid && passwordMatch) {

				let payload = {};

				if (this.inputs.email != this.user.email) payload.email = this.inputs.email;
				if (this.inputs.mobileNumber != this.user.mobileNumber) payload.mobileNumber = this.inputs.mobileNumber;
				if (this.inputs.role != this.user.role) payload.role = this.inputs.role;
				if (this.inputs.name != this.user.name) payload.name = this.inputs.name;
				if (this.inputs.password) payload.password = this.inputs.password;
				if (this.inputs.loginMethod != this.user.loginMethod) payload.loginMethod = this.inputs.loginMethod;
				if (this.inputs.accountStatus != this.user.accountStatus) payload.accountStatus = this.inputs.accountStatus;

				if (Object.keys(payload).length == 0) return;

				payload.id = this.inputs.id;

				this.loading = true;
				await this.updateAccount(payload)
					.then(() => {
						this.$toast.show('Account successfully updated');
						if (this.resetPassword == 'true') {
							if (cookie.get("role") == "reportUser") {
								this.$router.push(this.$route.query.redirect || `/${this.$i18n.locale}/!/reports`);
							} else {
								this.$router.push(this.$route.query.redirect || `/${this.$i18n.locale}/!`);
							}
						}
						this.user = { ...this.inputs };
					})
					.catch(err => {
						this.$toast.clear();
						this.$toast.error(err.msg);
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		roleValid() {
			if (!this.inputs.role || validator.isEmpty(this.inputs.role)) {
				this.errors.role = this.$i18n.locale == 'en' ? 'Role cannot be empty' : 'خانة نوع الحساب فارغة';
				return false;
			}
			this.errors.role = null;
			return true;
		},
		loginMethodValid() {
			if (!this.inputs.loginMethod || validator.isEmpty(this.inputs.loginMethod)) {
				this.errors.loginMethod = this.$i18n.locale == 'en' ? 'Login method cannot be empty' : 'خانة تسجيل الدخول فارغة';
				return false;
			}
			this.errors.loginMethod = null;
			return true;
		},
		nameValid() {
			if (!this.inputs.name || validator.isEmpty(this.inputs.name)) {
				this.errors.name = this.$i18n.locale == 'en' ? 'Name cannot be empty' : 'خانة الإسم فارغة';
				return false;
			}
			this.errors.name = null;
			return true;
		},
		emailValid() {
			if (!this.inputs.email || !validator.isEmail(this.inputs.email)) {
				this.errors.email = this.$i18n.locale == 'en' ? 'Email is incorrect' : 'الإيميل غير صحيح';
				return false;
			}
			this.errors.email = null;
			return true;
		},
		mobileNumberValid() {
			if (!this.inputs.mobileNumber || !validator.isMobilePhone(this.inputs.mobileNumber) || this.inputs.mobileNumber.match(/^[0]{1}[5]{1}[0-9]{1}[0-9]{7}$/g) == null) {
				this.errors.mobileNumber = this.$i18n.locale == 'en' ? 'Mobile number is incorrect' : 'رقم الهاتف غير صحيح';
				return false;
			}
			this.errors.mobileNumber = null;
			return true;
		},
		passwordValid() {
			if (!this.inputs.password || this.inputs.password == '') return true;
			if (validator.isEmpty(this.inputs.password)) {
				this.errors.password = this.$i18n.locale == 'en' ? 'Password cannot be empty' : 'خانة كلمة السر فارغة';
				return false;
			}
			this.errors.password = null;
			return true;
		},
	},
	watch: {
		'$i18n.locale'() {
			if (this.formValidated) {
				this.nameValid();
				this.emailValid();
			}
			if (this.$i18n.locale == 'en') {
				this.columns = [
					this.$t('dashboard.user.accessDescription'),
					this.$t('dashboard.user.actionBy'),
					this.$t('dashboard.user.userAgent'),
					this.$t('dashboard.user.ip'),
					this.$t('dashboard.user.date'),
					this.$t('dashboard.user.view'),
				];
			} else {
				this.columns = [
					this.$t('dashboard.user.accessDescription'),
					this.$t('dashboard.user.actionBy'),
					this.$t('dashboard.user.userAgent'),
					this.$t('dashboard.user.ip'),
					this.$t('dashboard.user.date'),
					this.$t('dashboard.user.view'),
				];
				this.columns.reverse();
			}
		},
	},
	components: {
		Dialogue,
		Overlay
	}
}
</script>

<style scoped>
.card {
	padding: 10px;
	background-color: #f8f8f8;
	border: 2.5px solid #f6f6f6;
}

textarea {
	resize: none;
}

.container[dir="rtl"] h4 {
	font-family: tajawal-bold;
}

.container h4 {
	font-family: lato-bold;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.header .buttons {
	display: flex;
	gap: 5px;
}

@media only screen and (max-width: 991px) {
	.header {
		flex-direction: column;
		align-items: flex-start;
	}
}

.nowrap {
	white-space: nowrap;
}

.header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.header button {
	margin: 0 5px;
}
</style>
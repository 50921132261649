<template>
    <div class="bg">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center mb-4">
                    <h1>{{ $t('index.contact.title') }}</h1>
                </div>
                <div class="col-12 col-lg-10 text-center d-flex flex-column align-items-center">
                    <h6>{{ $t('index.contact.location') }}</h6>
                    <h6>{{ $t('index.contact.tel') }} <a href="tel:+971-44410888">+971-44410888</a></h6>
                    <h6>{{ $t('index.contact.mob') }} <a href="tel:+971-504242202">+971-506458873</a></h6>
                    <div class="mapouter mt-4">
                        <div class="gmap_canvas">
                            <iframe width="400" height="300" id="gmap_canvas" src="https://maps.google.com/maps?q=Dorchester%20Properties%20Registration%20Trustee&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUs'
}
</script>

<style scoped>
.bg {
    padding: 50px;
}

.mapouter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    text-align: center;
    height: 300px;
    width: 400px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 300px;
    width: 400px;
}
</style>
function dateFormat(date) {
    let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return new Date(date).toLocaleTimeString("en-AE") + " " + new Date(date).toLocaleDateString("en-AE", options)
}
function returnTimePassed(date, locale='en') {
    let date_now = new Date();
    let date_past = new Date(date);
    // get total seconds between the times
    let delta = Math.abs(date_now - date_past) / 1000;

    // calculate (and subtract) whole days
    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    let seconds = delta % 60;
    seconds = Math.round(seconds)

    if(days != 0) {
        if(days == 1) days = days + ' day '
        else days = days + ' days '
    }
    else days = ''
    if(hours != 0) {
        if(hours == 1) hours = hours + ' hour '
        else hours = hours + ' hours '
    }
    else hours = ''
    if(minutes != 0) {
        if(minutes == 1) minutes = minutes + ' minute '
        else minutes = minutes + ' minutes '
    }
    else minutes = ''
    if(minutes == 0) {
        if(seconds == 1) seconds = seconds + ' second '
        else seconds = seconds + ' seconds '
    }
    else seconds = ''

    if(locale == 'ar') return "قبل " + seconds + minutes + hours + days
    else return days + hours + minutes + seconds + "ago"
}

export {
    dateFormat,
    returnTimePassed
}
import cookie from 'vue-cookies';
import axiosApiInstance from '../api';
import { error } from '../utils/apiHelpers';

export default {
    state: {
        refreshCookie: cookie.get('refreshCookie') || null,
        role: cookie.get('role') || null,
        name: cookie.get('name') || null,
        id: cookie.get('id') || null
    },
    mutations: {
        AUTH_SUCCESS(state, data) {
            state.refreshCookie = 'true';
            state.role = data.account.role;
            state.id = data.account.id;
            cookie.set('id', data.account.id, '30d');
            cookie.set('role', data.account.role, '30d');
            cookie.set('name', data.account.name, '30d');
            cookie.set('refreshCookie', 'true', '30d');
        },
        LOGOUT(state) {
            cookie.remove('refreshCookie');
            cookie.remove('role');
            cookie.remove('name');
            cookie.remove('id');
            state.refreshCookie = null;
            state.role = null;
            state.name = null;
            state.id = null;
        }
    },
    actions: {
        login({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.post('/login', payload)
                .then(res => {
                    if(res.status == 200) {
                        commit('AUTH_SUCCESS', res.data);
                        resolve(res);
                    } else if(res.status == 202) {
                        resolve(res);
                    } else {
                        reject(res.status);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        passwordReset({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.post('/account/resetpass', payload)
                .then(res => {
                    if(res.status == 200) {
                        resolve(res);
                    } else {
                        reject(res.status);
                    }
                })
                .catch(err => {
                    reject(error(err));
                });
            });
        },
        logout({ commit }) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.delete('/logout')
                .then(res => {
                    if(res.status == 204) {
                        commit('LOGOUT');
                        resolve(res);
                    } else {
                        reject(res);
                    }
                })
                .catch(err => {
                    commit('LOGOUT');
                    reject(error(err));
                });
            });
        },
        refreshAccessToken({ commit }) {
            return new Promise((resolve, reject) => {
                axiosApiInstance.post('/token')
                .then(res => {
                    if(res.status == 200) {
                        resolve(res);
                    } else {
                        commit('LOGOUT');
                        reject(error(res));
                    }
                })
                .catch(err => {
                    commit('LOGOUT');
                    reject(error(err));
                });
            });
        },
    },
    getters: {
        refreshCookie(state) {
            return state.refreshCookie == 'true';
        },
        role(state) {
            return state.role;
        },
        id(state) {
            return state.id;
        },
        name(state) {
            return state.name;
        }
    }
}
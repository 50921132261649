<template>
    <div class="bg" :dir="[ $i18n.locale == 'en' ? '' : 'rtl' ]">
        <div class="container" id="about">
            <div class="row justify-content-center">
                <div class="col-12 text-center title">
                    <h1>{{ $t('index.about.title') }}</h1>
                    <h6>{{ $t('index.about.subtitle') }}</h6>
                </div>
                <div class="col-12 col-lg-10 paragraph">
                    <img src="../../../assets/logo.png" alt="logo" class="logo">
                    <div>
                        <p>{{ $t('index.about.summary') }}</p>
                        <blockquote><span>{{ $t('index.about.blockquote') }}</span></blockquote>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About',
}
</script>

<style scoped>
.bg {
    background-color: rgb(36, 33, 31);
    color: #ffffff;
    padding: 50px;
}

h1 {
    color: #f7f6ff;
}

h6 {
    color: #f0e5ce;
}

p {
    font-size: 14px;
}

.title {
    margin-bottom: 20px;
}

.paragraph {
    display: flex;
    align-items: center;
}

blockquote {
    color: #efefef;
    font-weight: 300;
    padding: 10px;
    box-shadow: -5px 0 0 0 #ccc;
    font-style: italic;
    margin: 20px 30px;
}

img.logo {
    margin-right: 20px;
    height: 130px;
    border-radius: 3px;
}

*[dir="rtl"] img.logo {
    margin-left: 20px;
    margin-right: 0;
}

*[dir="rtl"] blockquote {
    box-shadow: 5px 0 0 0 #ccc;
}

@media only screen and (max-width: 767px) {
    .paragraph {
        text-align: center;
        flex-direction: column;
    }
}
</style>
<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-10 text-center">
                <div class="header" :class="[$i18n.locale == 'en' ? 'start' : 'end']">
                    <h1>{{ $t('dashboard.transactionsAdmin.title') }} <span v-if="role == 'admin'">( {{ transactionsCount }} )</span></h1>
                    <button class="btn btn-add" @click.prevent="$router.push(`/${$i18n.locale}/!/add-transaction`)"><img
                            src="../../../assets/icons/plus-w.png" alt="add"></button>
                    <button class="btn btn-add"
                        @click.prevent="$router.push(`/${$i18n.locale}/!/add-transaction-from-folder`)"><img
                            src="../../../assets/icons/upload-folder.png" alt="add folder"></button>
                </div>
            </div>
        </div>

        <div class="row justify-content-center d-flex align-items-end"
            :class="[$i18n.locale == 'en' ? '' : 'flex-row-reverse text-end']">
            <div class="col-12 col-sm-12 col-lg-2 col-xl-2 form-group mb-2 mb-sm-0">
                <label for="search">{{ $t('dashboard.transactions.search') }}</label>
                <input type="text" class="form-control" id="search" v-model="search"
                    :class="[$i18n.locale == 'en' ? '' : 'text-end']" />
            </div>
            <div class="col-sm-6 col-md-6 col-lg-2 form-group mb-2 mb-sm-0">
                <label for="after">{{ $t('dashboard.transactions.after') }}</label>
                <v-date-picker v-model="after" :locale="$i18n.locale" :first-day-of-week="1"
                    :select-attribute="selectAttribute">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input type="text" class="form-control" id="after" :value="inputValue" v-on="inputEvents"
                            :class="[$i18n.locale == 'en' ? '' : 'text-end']" readonly />
                    </template>
                </v-date-picker>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-2 form-group mb-2 mb-sm-0">
                <label for="before">{{ $t('dashboard.transactions.before') }}</label>
                <v-date-picker v-model="before" :locale="$i18n.locale" :first-day-of-week="1"
                    :select-attribute="selectAttribute">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input type="text" class="form-control" id="before" :value="inputValue" v-on="inputEvents"
                            :class="[$i18n.locale == 'en' ? '' : 'text-end']" readonly />
                    </template>
                </v-date-picker>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-2 form-group mb-2 mb-sm-0">
                <label for="account">{{ $t('dashboard.transactions.account') }}</label>
                <select id="account" class="form-select" v-model="accountId"
                    :class="[$i18n.locale == 'en' ? '' : 'text-end']">
                    <option value="" selected disabled>{{ $i18n.locale == 'en' ? 'Choose account' : 'اختر المستخدم' }}
                    </option>
                    <option v-for="user in users" v-bind:key="user.id" :value="user.id">{{ user.name }}</option>
                </select>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-2 form-group mb-2 mb-sm-0">
                <label for="status">{{ $t('dashboard.transactions.status') }}</label>
                <select id="status" class="form-select" v-model="status" :class="[$i18n.locale == 'en' ? '' : 'text-end']">
                    <option value="" selected disabled>{{ $i18n.locale == 'en' ? 'Choose a status' : 'اختر حالة' }}</option>
                    <option value="ALL">{{ $i18n.locale == 'en' ? statusFormatterEn('ALL') : statusFormatterAr('ALL') }}
                    </option>
                    <option value="PENDING">{{ $i18n.locale == 'en' ? statusFormatterEn('PENDING') :
                        statusFormatterAr('PENDING') }}</option>
                    <option value="PAID">{{ $i18n.locale == 'en' ? statusFormatterEn('PAID') : statusFormatterAr('PAID') }}
                    </option>
                    <option value="PAID-INOFFICE">{{ $i18n.locale == 'en' ? statusFormatterEn('PAID-INOFFICE') :
                        statusFormatterAr('PAID-INOFFICE') }}</option>
                    <option value="REFUNDED">{{ $i18n.locale == 'en' ? statusFormatterEn('REFUNDED') :
                        statusFormatterAr('REFUNDED') }}</option>
                    <option value="CANCELLED">{{ $i18n.locale == 'en' ? statusFormatterEn('CANCELLED') :
                        statusFormatterAr('CANCELLED') }}</option>
                </select>
            </div>
        </div>
        <div class="row justify-content-center d-flex align-items-end"
            :class="[$i18n.locale == 'en' ? '' : 'flex-row-reverse text-end']">
            <div class="col-12 col-lg-10 justify-content-end d-flex align-items-end">
                <div class="col-sm-3 col-lg-2 mb-2 mb-sm-0 mt-sm-1">
                    <a class="btn btn-main btn-search" @click.prevent="resetSearch()">{{
                        $t('dashboard.transactions.reset') }}</a>
                </div>
                <div class="col-sm-3 col-lg-2 mb-2 mb-sm-0 mt-sm-1">
                    <button class="btn btn-main btn-search" @click.prevent="searchTransactions()">{{
                        $t('dashboard.transactions.search') }}</button>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-lg-10 text-center">
                <div class="table-responsive" :class="[$i18n.locale == 'en' ? 'text-start' : 'text-end']">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" class="nowrap" :class="$i18n.locale" v-bind:key="index"
                                    v-for="(column, index) in columns">{{ column }}</th>
                            </tr>
                        </thead>
                        <tbody v-if="$i18n.locale == 'en'">
                            <tr v-bind:key="index" v-for="(transaction, index) in transactions">
                                <td>{{ new Date(transaction.transactionDate).toLocaleDateString('en-GB') }}</td>
                                <td>{{ (transaction.totalAmount / 100).toFixed(2) }}</td>
                                <td>{{ statusFormatterEn(transaction.status) }}</td>
                                <td>{{ transaction.buyerName }}</td>
                                <td>{{ transaction.sellerName }}</td>
                                <td>{{ transaction.procedureNumber }}</td>
                                <td>{{ transaction.titleDeedNumber }}</td>
                                <td>{{ transaction.accountName }}</td>
                                <td><router-link :to="transaction.id">{{ $t('dashboard.transactions.view') }}</router-link>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr v-bind:key="index" v-for="(transaction, index) in transactions">
                                <td><router-link :to="transaction.id">{{ $t('dashboard.transactions.view') }}</router-link>
                                </td>
                                <td>{{ transaction.accountName }}</td>
                                <td>{{ transaction.titleDeedNumber }}</td>
                                <td>{{ transaction.procedureNumber }}</td>
                                <td>{{ transaction.sellerName }}</td>
                                <td>{{ transaction.buyerName }}</td>
                                <td>{{ statusFormatterAr(transaction.status) }}</td>
                                <td>{{ (transaction.totalAmount / 100).toFixed(2) }}</td>
                                <td>{{ new Date(transaction.transactionDate).toLocaleDateString('en-GB') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p class="noTransactions" v-if="transactions.length == 0 && transactionsComplete">{{
                    $t('dashboard.transactions.noneFound') }}</p>
                <p class="noTransactions" v-if="transactions.length != 0 && transactionsComplete">{{
                    $t('dashboard.transactions.allLoaded') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from "luxon";

export default {
    name: 'Transactions',
    data() {
        return {
            columns: [
                this.$t('dashboard.transactions.date'),
                this.$t('dashboard.transactions.amount'),
                this.$t('dashboard.transactions.status'),
                this.$t('dashboard.transactions.buyerName'),
                this.$t('dashboard.transactions.sellerName'),
                this.$t('dashboard.transactions.procedureNumber'),
                this.$t('dashboard.transactions.titleDeed'),
                this.$t('dashboard.transactions.accountName'),
                this.$t('dashboard.transactions.link')
            ],
            loading: false,
            limit: 25,
            offset: 0,
            transactions: [],
            transactionsComplete: false,
            search: '',
            after: '',
            before: '',
            status: '',
            users: [],
            accountId: [],
            selectAttribute: {
                highlight: {
                    style: {
                        backgroundColor: "#464338",
                    },
                },
                content: {
                    style: {
                        color: "#ffffff",
                    },
                },
            },
            transactionsCount: 0,
        }
    },
    methods: {
        ...mapActions([
            'fetchTransactionsAdmin',
            'fetchTransactionsCountAdmin',
            'fetchAccountNames'
        ]),
        statusFormatterEn(status) {
            switch (status) {
                case 'ALL': return 'All'
                case 'PAID': return 'Paid'
                case 'PENDING': return 'Pending'
                case 'PAID-INOFFICE': return 'Paid in office'
                case 'REFUNDED': return 'Refunded'
                case 'CANCELLED': return 'Cancelled'
                default: return status;
            }
        },
        statusFormatterAr(status) {
            switch (status) {
                case 'ALL': return 'جميع ما يلي'
                case 'PAID': return 'تم الدفع'
                case 'PENDING': return 'غير مدفوع'
                case 'PAID-INOFFICE': return 'تم الدفع في المكتب'
                case 'REFUNDED': return 'المبلغ معاد'
                case 'CANCELLED': return 'ملغي'
                default: return status;
            }
        },
        async getTransactions() {
            if (this.loading || this.transactionsComplete) return;
            let payload = {};
            payload.limit = this.limit;
            payload.offset = this.offset;
            let query = {}
            if (this.search != '') {
                payload.search = this.search.trim();
                query.search = payload.search
            } else {
                query.search = ""
            }
            if (this.after != '' && this.after != null) {
                payload.after = DateTime.fromJSDate(this.after).toFormat('dd-MM-yyyy');
                query.after = payload.after;
            } else {
                query.after = ""
            }
            if (this.before != '' && this.before != null) {
                payload.before = DateTime.fromJSDate(this.before).toFormat('dd-MM-yyyy');
                query.before = payload.before;
            } else {
                query.before = ""
            }
            if (this.status != '') {
                payload.status = this.status;
                query.status = payload.status;
            } else {
                query.status = ""
            }
            if (this.accountId != '') {
                payload.accountId = this.accountId;
                query.accountId = payload.accountId;
            } else {
                query.accountId = ""
            }
            this.$router.push({ query })

            this.loading = true;
            await this.fetchTransactionsAdmin(payload)
                .then(data => {
                    this.transactions.push(...data.transactions);
                    this.offset += data.transactions.length;
                    if (data.transactions.length < this.limit) {
                        this.transactionsComplete = true;
                    }
                })
                .catch(err => {
                    this.$toast.clear();
                    this.$toast.error(err.msg || err.status);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getTransactionsCount() {
            if (this.loading || this.transactionsComplete || this.role != 'admin') return;
            let payload = {};
            if (this.search != '') {
                payload.search = this.search.trim();
            }
            if (this.after != '' && this.after != null) {
                payload.after = DateTime.fromJSDate(this.after).toFormat('dd-MM-yyyy');
            }
            if (this.before != '' && this.before != null) {
                payload.before = DateTime.fromJSDate(this.before).toFormat('dd-MM-yyyy');
            }
            if (this.status != '') {
                payload.status = this.status;
            }
            if (this.accountId != '') {
                payload.accountId = this.accountId;
            }
            this.loading = true;
            await this.fetchTransactionsCountAdmin(payload)
                .then(data => {
                    this.transactionsCount = data.count
                })
                .catch(err => {
                    this.$toast.clear();
                    this.$toast.error(err.msg || err.status);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getUsers() {
            if (this.loading || this.usersComplete) return;
            let payload = {};
            payload.limit = 50;
            payload.offset = 0;
            this.loading = true;
            await this.fetchAccountNames(payload)
                .then(data => {
                    this.users.push({ id: '', name: 'All' })
                    this.users.push(...data.accounts);
                })
                .catch(err => {
                    this.$toast.clear();
                    this.$toast.error(err.msg || err.status);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        resetSearch() {
            let query = {}
            this.search = '';
            this.before = '';
            this.after = '';
            this.accountId = '';
            this.status = '';
            query.search = '';
            query.before = '';
            query.after = '';
            query.accountId = '';
            query.status = '';
            this.$router.push({ query })
            this.searchTransactions();
        },
        handleScroll() {
            const documentOffsetHeight = document.documentElement.offsetHeight;
            const documentScrollTop = Math.round(document.documentElement.scrollTop); //Math.round(window.scrollY);
            const windowInnerHeight = window.innerHeight;
            const atBottomOfWindow = documentScrollTop + windowInnerHeight == documentOffsetHeight || documentScrollTop + windowInnerHeight > documentOffsetHeight - 20;
            if (atBottomOfWindow && !this.loading) {
                this.getTransactions();
            }
        },
        async searchTransactions() {
            this.offset = 0;
            this.transactions = [];
            this.transactionsComplete = false;
            await this.getTransactionsCount();
            await this.getTransactions();
        }
    },
    async created() {
        this.search = this.$route.query.search || ''
        this.before = this.$route.query.before || ''
        this.after = this.$route.query.after || ''
        this.accountId = this.$route.query.accountId || ''
        this.status = this.$route.query.status || ''

        if (this.after != '') this.after = DateTime.fromFormat(this.after, 'dd-MM-yyyy').toJSDate();
        if (this.before != '') this.before = DateTime.fromFormat(this.before, 'dd-MM-yyyy').toJSDate();

        if (this.$i18n.locale == 'ar') {
            this.columns.reverse();
        }
        await this.getUsers();
        await this.getTransactionsCount();
        await this.getTransactions();
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    unmounted() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    watch: {
        '$i18n.locale'() {
            if (this.$i18n.locale == 'en') {
                this.columns = [
                    this.$t('dashboard.transactions.date'),
                    this.$t('dashboard.transactions.amount'),
                    this.$t('dashboard.transactions.status'),
                    this.$t('dashboard.transactions.buyerName'),
                    this.$t('dashboard.transactions.sellerName'),
                    this.$t('dashboard.transactions.procedureNumber'),
                    this.$t('dashboard.transactions.titleDeed'),
                    this.$t('dashboard.transactions.accountName'),
                    this.$t('dashboard.transactions.link')
                ];
            } else {
                this.columns = [
                    this.$t('dashboard.transactions.date'),
                    this.$t('dashboard.transactions.amount'),
                    this.$t('dashboard.transactions.status'),
                    this.$t('dashboard.transactions.buyerName'),
                    this.$t('dashboard.transactions.sellerName'),
                    this.$t('dashboard.transactions.procedureNumber'),
                    this.$t('dashboard.transactions.titleDeed'),
                    this.$t('dashboard.transactions.accountName'),
                    this.$t('dashboard.transactions.link')
                ];
                this.columns.reverse();
            }
        }
    },
    computed: {
        ...mapGetters([
            'role'
        ])
    }
}
</script>

<style scoped>
.container {
    min-height: 110vh;
}

table.table.ar {
    text-align: end;
}

.nowrap {
    white-space: nowrap;
}

.en {
    font-family: lato-bold;
}

.ar {
    font-family: tajawal-bold;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.header.end {
    flex-direction: row-reverse;
    align-items: flex-start;
}

.header button {
    margin: 0 5px;
}

button img {
    height: 12px;
}

.btn-search {
    margin: 0;
    padding: 0.375rem 1rem;
    width: 100%;
}

td {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
<template>
    <div class="page-container" :style="paddingBottom" :class="{ booking : $route.path.includes(`book`) }">
        <div class="image" v-if="showImage"></div>
        <Navbar :class="{ booking : $route.path.includes(`book`) }"></Navbar>
        <router-view></router-view>
        <Footer></Footer>
    </div>
</template>

<script>
import Navbar from '../components/index/shared/Navbar';
import Footer from '../components/index/shared/Footer';

export default {
    name: 'Index',
    components: {
        Navbar,
        Footer
    },
    data() {
        return {
            paddingBottom: '',
            showImage: true,
        }
    },
    created() {
        this.routeChanged();
    },
    methods: {
        handleResize() {
            let elem = document.getElementById('footer');
            this.paddingBottom = `padding-bottom: ${elem.offsetHeight}px`;
        },
        routeChanged() {
            const route = this.$route.path.split('/').filter(a => a != '');
            const bookingPage = route[route.length - 1];
            const paymentPage = route[route.length - 2];
            if(bookingPage == 'book' || paymentPage == 'payment') this.showImage = false;
            else this.showImage = true;
        }
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
    },
    unmounted() {
        window.removeEventListener("resize", this.handleResize);
    },
    watch: {
        '$route'() {
            this.routeChanged();
        }
    }
}
</script>

<style scoped>
.page-container {
    min-height: calc(100vh);
    position: relative;
    padding-bottom: 350.94px;
}

.image {
    background-image: url('../assets/bg-16by9.jpg');
    background-position-y: top;
    background-position-x: center;
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-size: cover;
}

.booking {
    position: absolute;
    width: 100%;
}

.page-container.booking {
    background-color: rgba(247, 247, 247, 1);
}

@media only screen and (max-width: 575px) {
    .page-container {
        padding-bottom: 595.72px;
    }
}
</style>
<template>
	<div class="container" :dir="[$i18n.locale == 'ar' ? 'rtl' : '']">
		<div class="row justify-content-center">
			<div class="col-12 col-lg-10">
				<div class="header">
					<h1> {{ $t("dashboard.addTransaction.title") }}</h1>
					<button class="btn btn-main" @click.prevent="validateForm" :disabled="loading">{{ $t("dashboard.addTransaction.add") }}</button>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3">
				<div class="card">
					<h4>{{ $t("dashboard.transaction.paymentInformation") }}</h4>
					<div class="row mb-3">
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="name" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.name") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.fulfillerName }"
										type="text"
										class="form-control"
										id="name"
										v-model="inputs.fulfillerName"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.fulfillerName">
										{{ errors.fulfillerName }}
									</div>
								</div>	
							</div>
							<div class="row mb-3">
								<label for="email" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.email") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.fulfillerEmail }"
										type="email"
										class="form-control"
										id="email"
										v-model="inputs.fulfillerEmail"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.fulfillerEmail">
										{{ errors.fulfillerEmail }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="mobile" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.mobileNumber") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.fulfillerMobileNumber }"
										type="text"
										class="form-control"
										id="mobile"
										v-model="inputs.fulfillerMobileNumber"
										maxlength="15"
									/>
									<div class="invalid-feedback" v-if="errors.fulfillerMobileNumber">
										{{ errors.fulfillerMobileNumber }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="status" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.status") }}</label>
								<div class="col-sm-9">
									<select class="form-select" v-model="inputs.status" v-bind:class="{ invalid : errors.status }">
                                        <option disabled selected value="null">{{ $t('dashboard.addTransaction.chooseStatus') }}</option>
                                        <option :value="$t('dashboard.addTransaction.statuses.pending.id')">{{ $t('dashboard.addTransaction.statuses.pending.value') }}</option>
                                        <option :value="$t('dashboard.addTransaction.statuses.paidInOffice.id')">{{ $t('dashboard.addTransaction.statuses.paidInOffice.value') }}</option>
                                    </select>
                                    <div class="invalid-feedback" v-if="errors.status">
										{{ errors.status }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="paymentNotes" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.paymentNotes") }}</label>
								<div class="col-sm-9">
									<textarea class="form-control" id="paymentNotes" rows="3" v-model="inputs.paymentNotes" maxlength="500"></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-10 mb-3">
				<div class="card">
					<h4>{{ $t("dashboard.transaction.generalInformation") }}</h4>
					<div class="row mb-3">
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="sellerLastName" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.sellerName") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.sellerName }"
										type="text"
										class="form-control"
										id="sellerLastName"
										v-model="inputs.sellerName"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.sellerName">
										{{ errors.sellerName }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="buyerLastName" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.buyerName") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.buyerName }"
										type="text"
										class="form-control"
										id="buyerLastName"
										v-model="inputs.buyerName"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.buyerName">
										{{ errors.buyerName }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="area" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.area") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.area }"
										type="text"
										class="form-control"
										id="area"
										v-model="inputs.area"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.area">
										{{ errors.area }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="building" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.building") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.building }"
										type="text"
										class="form-control"
										id="building"
										v-model="inputs.building"
										maxlength="100"
									/>
									<div class="invalid-feedback" v-if="errors.building">
										{{ errors.building }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="procedureNumber" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.procedureNumber") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.procedureNumber }"
										type="text"
										class="form-control"
										id="procedureNumber"
										v-model="inputs.procedureNumber"
										maxlength="50"
									/>
									<div class="invalid-feedback" v-if="errors.procedureNumber">
										{{ errors.procedureNumber }}
									</div>
								</div>
							</div>
							<div class="row mb-3">
								<label for="titleDeed" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.titleDeed") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.titleDeedNumber }"
										type="text"
										class="form-control"
										id="titleDeed"
										v-model="inputs.titleDeedNumber"
										maxlength="50"
									/>
									<div class="invalid-feedback" v-if="errors.titleDeedNumber">
										{{ errors.titleDeedNumber }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="totalAmount" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.totalAmount") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.totalAmount }"
										type="number"
										class="form-control"
										id="totalAmount"
										v-model="inputs.totalAmount"
									/>
									<div class="invalid-feedback" v-if="errors.totalAmount">
										{{ errors.totalAmount }}
									</div>
								</div>
							</div>
							<!-- <div class="row mb-3">
								<label for="commissionAmount" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.commissionAmount") }}</label>
								<div class="col-sm-9">
									<input v-bind:class="{ invalid : errors.commissionAmount }"
										type="number"
										class="form-control"
										id="commissionAmount"
										v-model="inputs.commissionAmount"
									/>
									<div class="invalid-feedback" v-if="errors.commissionAmount">
										{{ errors.commissionAmount }}
									</div>
								</div>
							</div> -->
							<div class="row mb-3">
								<label for="type" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.type") }}</label>
								<div class="col-sm-9">
									<select class="form-select" v-model="inputs.type" v-bind:class="{ invalid : errors.type }">
                                        <option disabled selected value="null">{{ $t('dashboard.addTransaction.chooseType') }}</option>
                                        <option :value="$t('dashboard.addTransaction.types.RT.id')">{{ $t('dashboard.addTransaction.types.RT.value') }}</option>
                                        <option :value="$t('dashboard.addTransaction.types.ST.id')">{{ $t('dashboard.addTransaction.types.ST.value') }}</option>
                                    </select>
                                    <div class="invalid-feedback" v-if="errors.type">
										{{ errors.type }}
									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="transactionDate" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.transactionDate") }}</label>
								<div class="col-sm-9">
									<v-date-picker v-model="inputs.transactionDate" :locale="$i18n.locale" :first-day-of-week="1" :select-attribute="selectAttribute">
										<template v-slot="{ inputValue, inputEvents }">
										<input type="text"
											class="form-control"
											id="transactionDate"
											:value="inputValue"
											v-on="inputEvents"
											readonly />
										</template>
									</v-date-picker>
								</div>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="row mb-3">
								<label for="notes" class="col-form-label col-sm-3">{{ $t("dashboard.transaction.notes") }}</label>
								<div class="col-sm-9">
								<textarea
									class="form-control"
									id="notes"
									rows="3"
									v-model="inputs.notes"
									maxlength="500"
								></textarea>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import validator from 'validator';
import { mapActions } from "vuex";

export default {
	name: "AddTransaction",
	data() {
		return {
			loading: false,
			formValidated: false,
			inputs: {
                fulfillerName: '',
				fulfillerEmail: '',
				fulfillerMobileNumber: '',
                status: null,
				type: null,
				sellerName: '',
				buyerName: '',
				procedureNumber: '',
				titleDeedNumber: '',
				area: '',
				building: '',
				totalAmount: '',
				// commissionAmount: '',
				transactionDate: new Date(),
                notes: '',
                paymentNotes: ''
			},
			errors: {
				fulfillerName: null,
				fulfillerEmail: null,
				fulfillerMobileNumber: null,
				sellerName: null,
				buyerName: null,
				procedureNumber: null,
				titleDeedNumber: null,
				area: null,
				building: null,
				totalAmount: null,
				// commissionAmount: null,
				status: null,
				type: null,
			},
			files: [],
			selectAttribute: {
				highlight: {
					style: {
						backgroundColor: "#464338",
					},
				},
				content: {
					style: {
						color: "#ffffff",
					},
				},
			},
		}
	},
	methods: {
		...mapActions([
			"addTransaction"
		]),
		async validateForm() {
			if(this.loading) return;
			this.formValidated = true;

            const nameValid = this.nameValid();
            const emailValid = this.emailValid();
            const statusValid = this.statusValid();
			const typeValid = this.typeValid();
            const mobileValid = this.mobileValid();
            const amountValid = this.amountValid();
            // const commissionValid = this.commissionValid();
			
			if(nameValid && emailValid && statusValid && typeValid && mobileValid && amountValid) {

					let payload = {};

					payload.fulfillerName = this.inputs.fulfillerName;
					payload.fulfillerEmail = this.inputs.fulfillerEmail;
					payload.fulfillerMobileNumber = this.inputs.fulfillerMobileNumber;
					payload.status = this.inputs.status;
					payload.type = this.inputs.type;
					payload.paymentNotes = this.inputs.paymentNotes;
					payload.sellerName = this.inputs.sellerName;
					payload.buyerName = this.inputs.buyerName;
                    payload.area = this.inputs.area;
                    payload.building = this.inputs.building;
					payload.procedureNumber = this.inputs.procedureNumber.toString();
					payload.titleDeedNumber = this.inputs.titleDeedNumber.toString();
					payload.transactionDate = this.inputs.transactionDate.toISOString();
					payload.totalAmount = (this.inputs.totalAmount * 100).toString();
					//payload.commissionAmount = (this.inputs.commissionAmount * 100).toString();
					payload.notes = this.inputs.notes;

					this.loading = true;
					await this.addTransaction(payload)
					.then(data => {
						this.$toast.show('Transaction successfully added');
                        this.$router.push(`/${this.$i18n.locale}/!/${data.id}`);
					})
					.catch(err => {
						this.$toast.clear();
						this.$toast.show(err.msg);
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
        statusValid() {
            if(this.inputs.status == null) {
                this.errors.status = this.$i18n.locale == 'en' ? 'Please choose a status' : 'خانة الحالة فارغة';
				return false;
            }
            this.errors.status = null;
			return true;
        },
		typeValid() {
            if(this.inputs.type == null) {
                this.errors.type = this.$i18n.locale == 'en' ? 'Please choose a type' : 'خانة النوع فارغة';
				return false;
            }
            this.errors.type = null;
			return true;
        },
		nameValid() {
			if(this.inputs.status == 'PENDING' && validator.isEmpty(this.inputs.fulfillerName)) {
				this.errors.fulfillerName = this.$i18n.locale == 'en' ? 'Name cannot be empty' : 'خانة الإسم فارغة';
				return false;
			}
			if(this.inputs.fulfillerName.length > 100) {
				this.errors.fulfillerName = this.$i18n.locale == 'en' ? 'Name is too long' : 'الاسم طويل جدا';
				return false;
			}
			this.errors.fulfillerName = null;
			return true;
		},
		mobileValid() {
			if(!this.inputs.fulfillerMobileNumber || this.inputs.fulfillerMobileNumber == '') return true;
			if(!validator.isMobilePhone(this.inputs.fulfillerMobileNumber)) {
				this.errors.fulfillerMobileNumber = this.$i18n.locale == 'en' ? 'Mobile number is incorrect' : 'رقم الهاتف غير صحيح';
				return false;
			}
			if(this.inputs.fulfillerMobileNumber.length > 15) {
				this.errors.fulfillerMobileNumber = this.$i18n.locale == 'en' ? 'Mobile number is incorrect' : 'رقم الهاتف غير صحيح';
				return false;
			}
			this.errors.fulfillerMobileNumber = null;
			return true;
		},
		emailValid() {
			if(this.inputs.status == 'PENDING' && !validator.isEmail(this.inputs.fulfillerEmail)) {
				this.errors.fulfillerEmail = this.$i18n.locale == 'en' ? 'Email is incorrect' : 'الإيميل غير صحيح';
				return false;
			}
			if(this.inputs.fulfillerEmail.length > 100) {
				this.errors.fulfillerEmail = this.$i18n.locale == 'en' ? 'Email is too long' : 'البريد الإلكتروني طويل جدًا';
				return false;
			}
			this.errors.fulfillerEmail = null;
			return true;
		},
		amountValid() {
			if(!validator.isNumeric(this.inputs.totalAmount.toString())) {
				this.errors.totalAmount = this.$i18n.locale == 'en' ? 'Amount is incorrect' : 'قيمة الدفع غير صحيحة';
				return false;
			}
			if(this.inputs.totalAmount > 10000000) {
				this.errors.totalAmount = this.$i18n.locale == 'en' ? 'Amount is more than the maximum allowed payment' : 'المبلغ أكثر من الحد الأقصى للدفع المسموح به';
				return false;
			}
			this.errors.totalAmount = null;
			return true;
		},
		// commissionValid() {
		// 	if(!this.inputs.commissionAmount || this.inputs.commissionAmount == '') return true;
		// 	if(!validator.isNumeric(this.inputs.commissionAmount.toString())) {
		// 		this.errors.commissionAmount = this.$i18n.locale == 'en' ? 'Amount is incorrect' : 'قيمة العمولة غير صحيحة';
		// 		return false;
		// 	}
		// 	if(this.inputs.commissionAmount > 10000000) {
		// 		this.errors.commissionAmount = this.$i18n.locale == 'en' ? 'Amount is more than the maximum allowed payment' : 'المبلغ أكثر من الحد الأقصى للدفع المسموح به';
		// 		return false;
		// 	}
		// 	this.errors.commissionAmount = null;
		// 	return true;
		// }
	},
	watch: {
		'$i18n.locale'() {
            if(this.formValidated) {
                this.nameValid();
                this.mobileValid();
                this.emailValid();
                this.statusValid();
				this.typeValid();
                this.amountValid();
				// this.commissionValid();
            }
		},
		'inputs.fulfillerName'() {
			if(this.formValidated) this.nameValid();
		},
		'inputs.fulfillerMobileNumber'() {
			if(this.formValidated) this.mobileValid();
		},
        'inputs.fulfillerEmail'() {
			if(this.formValidated) this.emailValid();
		},
        'inputs.status'() {
			if(this.formValidated) this.statusValid();
		},
		'inputs.type'() {
			if(this.formValidated) this.typeValid();
		},
        'inputs.totalAmount'() {
            if(this.formValidated) this.amountValid();
		},
		// 'inputs.commissionValid'() {
        //     if(this.formValidated) this.commissionValid();
		// },
	}
}
</script>

<style scoped>
.card {
	padding: 10px;
	background-color: #f8f8f8;
	border: 2.5px solid #f6f6f6;
}

textarea {
	resize: none;
}

.container[dir="rtl"] h4 {
	font-family: tajawal-bold;
}

.container h4 {
	font-family: lato-bold;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
</style>
<template>
    <div class="container mt-3" :dir="[$i18n.locale == 'ar' ? 'rtl' : '']">
        <div class="row justify-content-center">
            <div class="col-12 col-sm-10 col-md-7">
                <h1>{{ $t('index.payment.title') }}</h1>
            </div>
            <div class="col-12 col-sm-10 col-md-7 mb-3">
                <div class="card">
                    <h4>{{ $t('index.payment.payerInformation') }}</h4>
                    <table class="table">
                        <tr>
                            <td>{{ $t('index.payment.name') }}</td>
                            <td>{{ transaction.fulfillerName }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('index.payment.email') }}</td>
                            <td>{{ transaction.fulfillerEmail }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('index.payment.procedureNumber') }}</td>
                            <td>{{ transaction.procedureNumber }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('index.payment.titleDeedNumber') }}</td>
                            <td>{{ transaction.titleDeedNumber }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('index.payment.notes') }}</td>
                            <td>{{ transaction.paymentNotes }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-12 col-sm-10 col-md-7 mb-3">
                <div class="card">
                    <h4>{{ $t('index.payment.paymentInformation') }}</h4>
                    <table class="table">
                        <tr>
                            <td>{{ $t('index.payment.transactionNumber') }}</td>
                            <td>{{ transaction.id }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('index.payment.amount') }}</td>
                            <td>{{ (transaction.totalAmount / 100).toFixed(2) }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('index.payment.status') }}</td>
                            <td>{{ $i18n.locale == 'en' ? statusFormatterEn(transaction.status) : statusFormatterAr(transaction.status) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="col-12 col-sm-10 col-md-8 col-xl-7 col-xxl-6 d-flex justify-content-center mb-3">
                <button class="btn btn-main" v-if="transaction.referenceToken" @click.prevent="openFoloosi">{{ $t('index.payment.pay') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'Payment',
    data() {
        return {
            loading: false,
            transaction: {},
            clientSecret: null,
            submitPayment: false,
            fp1: null,
        }
    },
    methods: {
        ...mapActions([
            'fetchTransactionNoAuth',
            'checkTransactionPayment'
        ]),
        statusFormatterEn(status) {
            switch(status) {
                case 'PAID': return 'Paid'
                case 'PENDING': return 'Pending'
                case 'PAID-INOFFICE': return 'Paid in office'
                case 'REFUNDED': return 'Refunded'
                case 'CANCELLED': return 'Cancelled'
                default: return status;
            }
        },
        statusFormatterAr(status) {
            switch(status) {
                case 'PAID': return 'تم الدفع'
                case 'PENDING': return 'غير مدفوع'
                case 'PAID-INOFFICE': return 'تم الدفع في المكتب'
                case 'REFUNDED': return 'المبلغ معاد'
                case 'CANCELLED': return 'ملغي'
                default: return status;
            }
        },
        async openFoloosi() {
            this.fp1.open();
        },
        async success() {
            this.$toast.show('Payment successful');
            this.submitPayment = false;
            let payload = {};
            payload.id = this.transaction.id;
            this.loading = true;
            await this.checkTransactionPayment(payload)
            .then(() => {
                this.transaction.status = 'PAID';
            })
            .catch(err => {
                this.$toast.clear();
                this.$toast.show(err.msg);
            })
            .finally(() => {
                this.loading = false;
            });
        },
        error(err) {
            this.$toast.clear();
            this.$toast.error(err);
        },
        async foloosiHandler(e) {
            if(e.data.status == 'success'){
                //responde success code
                console.log(e.data.status);
                console.log(e.data.data.transaction_no);
                this.$toast.show('Payment successful');
                this.submitPayment = false;
                let payload = {};
                payload.id = this.transaction.id;
                payload.foloosiID = e.data.data.transaction_no;
                this.loading = true;
                await this.checkTransactionPayment(payload)
                .then(() => {
                    this.transaction.status = 'PAID';
                })
                .catch(err => {
                    this.$toast.clear();
                    this.$toast.show(err.msg);
                })
                .finally(() => {
                    this.loading = false;
                });
            }
            if(e.data.status == 'error'){
                //responde success code
                console.log(e.data.status);
                console.log(e.data.data.payment_status);
            }
            if(e.data.status == 'closed'){
                //responde success code
                console.log(e.data.status);
            }
        }
    },
    async created() {
        let id = this.$route.params.id;
        let payload = { id };
        this.loading = true;
        await this.fetchTransactionNoAuth(payload)
        .then(data => {
            this.transaction = data.transaction;
            this.clientSecret = data.transaction.clientSecret;
            let options = {
                "reference_token": this.transaction.referenceToken,
                "merchant_key": "live_$2y$10$bg1KfvM4RfYn7z1p.ToxvOIFgRu7vINg72n0vi2P4jFO8LTHyA2Ji"
            }
            this.fp1 = new window.Foloosipay(options);

            if(!window.response) {
                this.$router.go(0)
            }
            addEventListener(window.response, this.foloosiHandler);
        })
        .catch(err => {
            this.$toast.clear();
            this.$toast.error(err.msg);
            console.log(err)
        })
        .finally(() => {
            this.loading = false;
        });
    },
    mounted() {
        let foloosiScript = document.createElement('script')
        foloosiScript.setAttribute('src', 'https://www.foloosi.com/js/foloosipay.v2.js')
        document.head.appendChild(foloosiScript)
    },
    components: {
    }
}
</script>

<style scoped>
.card {
    background-color: rgba(248, 248, 248, 0.4);
    backdrop-filter: blur(6px);
    padding: 40px 35px;
    border-radius: 5px;
    border: none;
    color: #000000;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.card > * {
    color: inherit;
}

.card > .btn-main {
    color: #ffffff;
    width: fit-content;
}


table tr {
    border-top: 2px solid rgba(83, 75, 66, 0.35);
}

table tr:last-child {
    border-bottom: 2px solid rgba(83, 75, 66, 0.35);
}

.container[dir="rtl"] tr td:first-child {
    font-family: tajawal-bold;
}

tr td:first-child {
    font-family: lato-bold;
}

td {
    width: 50%;
    padding: 7px 0;
}

h4 {
    color: #000000 !important;
}


.container[dir="rtl"] h4, .container[dir="rtl"] h1 {
	font-family: tajawal-bold;
}

.container h4, .container h1 {
	font-family: lato-bold;
}

.btn-main {
    padding: 15px 35px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
</style>
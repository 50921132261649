import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Toaster from '@meforma/vue-toaster';
import i18n from './i18n';
import VueProgressBar from "@aacassandra/vue3-progressbar";
import VCalendar from 'v-calendar';


const app = createApp(App);

app.use(store);
app.use(router);
app.use(Toaster, {
    position: "bottom",
    pauseOnHover: false,
    duration: 2500,
});
app.use(VueProgressBar, {
    color: "#464338",
    failedColor: "#874b4b",
    thickness: "2.5px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
});
app.use(i18n);
app.use(VCalendar, {
    locales: {
        'ar': {
            masks: {
                input: 'DD/MM/YYYY',
            }
        },
        'en': {
            masks: {
                input: 'DD/MM/YYYY',
                weekdays: 'WW'
            }
        }
    }
});

app.mount('#app');

export default app;